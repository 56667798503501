import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { signout, swapAccounts } from "../../redux/actions/session-action-types";
import ClearCache from "../../ClearCache";
import { useIntercom } from "react-use-intercom";

const DashboardNav = function (props) {
  const dispatch = useDispatch();
  const [isPopUp, setIsPopUp] = useState(true);
  const firstName = useSelector((state) => state.session.user?.firstName);
  const lastName = useSelector((state) => state.session.user?.lastName);

  const AccountID = useSelector((state) => state.session.account?.id);
  const role = useSelector((state) => state.session.role);
  const { shutdown } = useIntercom();
  const NavigationLinks = {
    super: [
      {
        link: "/accounts",
        text: "Accounts",
      },
      {
        link: "/users",
        text: "Users",
      },
      {
        link: "/organisations",
        text: "Organizations",
      },
      {
        link: "/usecases",
        text: "Use cases",
      },
    ],
    admin: [
      {
        link: `/accounts/${AccountID}/details`,
        text: "Account",
      },
    ],
  };

  return (
    <>
    <nav className="navbar navbar-expand-md navbar-light bg-white fixed-top border-bottom">
      <div className="dropdown mr-md-3">
        <button
          className="btn btn-light bg-white shadow-sm"
          style={{ border: "solid 1px rgba(0, 0, 0, 0.15)" }}
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="/images/dragonflyai-logo_admin_on-light.svg"
            height="24"
            className="float-left"
            alt="Dragonfly"
          />
          <span className="d-inline-block float-left ml-1">
            <i className="far fa-angle-down"></i>
          </span>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <h6 className="dropdown-header">Switch to</h6>
          <a className="dropdown-item" href={process.env.REACT_APP_STUDIO_URL}>
            <i className="far fa-fw fa-desktop mr-1"></i> Studio
          </a>
          <a className="dropdown-item" href="https://academy.dragonflyai.co">
            <i className="far fa-fw fa-graduation-cap mr-1"></i> Academy
          </a>
          <div className="dropdown-divider"></div>
          <h6 className="dropdown-header">Downloads</h6>
          <a
            className="dropdown-item"
            href={process.env.REACT_APP_EXTENSION_URL}
          >
            <i className="fab fa-fw fa-chrome mr-1"></i> Extension
          </a>
          <a className="dropdown-item" href={process.env.REACT_APP_APP_URL}>
            <i className="far fa-fw fa-tablet mr-1"></i> App
          </a>
        </div>
      </div>
      <NavLink
        className="navbar-brand mr-md-2"
        to={
          role === "super"
            ? "/accounts"
            : role === "admin"
            ? `/accounts/${AccountID}/details`
            : "/profile/basic-info"
        }
      ></NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse py-3 py-md-0"
        id="navbarCollapse"
      >
        <ul className="navbar-nav mr-auto">
          {("super" === role || "admin" === role) &&
            NavigationLinks[role].map(({ link, text }, indx) => (
              <li key={indx} className="nav-item">
                <NavLink className="nav-link" to={link}>
                  {text}
                </NavLink>
              </li>
            ))}
          <li className="nav-item">
            <NavLink className="nav-link" to="/profile/basic-info">
              Profile
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav flex-nowrap">
          <li className="nav-item dropdown">
            <div
              className="menu-item nav-link dropdown-toggle d-block pointer"
              id="accountDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {`${firstName} ${lastName}`}
            </div>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="accountDropdown"
            >
              {role === "super" ?
              <span
                className="dropdown-item pointer"
                onClick={() => {
                  dispatch(swapAccounts());
                }}
              >
                <i className="far fa-sign-out mr-1"></i> Change Accounts
              </span> 
                : null}
              <span
                className="dropdown-item pointer"
                onClick={() => {
                  /** Remove intercom when log out of the app */
                  shutdown();
                  dispatch(signout());
                }}
              >
                <i className="far fa-sign-out mr-1"></i> Sign out
              </span>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <ClearCache isPopUp ={isPopUp} setIsPopUp={setIsPopUp}/>
    </>
  );
};

export default DashboardNav;
