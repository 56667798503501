import React from "react";
const SortBox = ({ orderby, sortby, keyName, label, onSorting, ...props }) => {
  return (
    <th className="p-0" scope="col" {...props}>
      <div className="dropdown">
        <button
          className="btn btn-light btn-block text-left rounded-0 clearfix has-icon has-icon-right"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {label}
          <span className="icon">
            <i
              className={`${keyName === orderby ? `far fa-fw fa-long-arrow-${"asc" === sortby ? "up" : "down"}` : "fas text-muted fa-caret-down"}`}
            ></i>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <h6 className="dropdown-header">Sort</h6>
          <span
            className={`dropdown-item pointer ${keyName === orderby && "asc" === sortby ? "disabled" : ""}`}
            onClick={(e) => onSorting(e, keyName, "asc")}
          >
            Ascending
          </span>
          <span
            className={`dropdown-item pointer ${keyName === orderby && "desc" === sortby ? "disabled" : ""}`}
            onClick={(e) => onSorting(e, keyName, "desc")}
          >
            Descending
          </span>
        </div>
      </div>
    </th>
  );
};

export default SortBox;
