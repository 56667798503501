import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignoutUser } from "@/hooks/useSignoutUser";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

export const SignoutUser = () => {
  useSignoutUser();

  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{ marginTop: "-3.5rem" }}
    >
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <div className="text-center">
          <div className="py-4">
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              size="2x"
              className="text-muted mb-2"
            />
            <p className="lead">Please wait...</p>
          </div>
        </div>
      </div>
    </div>
  );
};
