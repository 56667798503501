import React, { useState, useEffect } from "react";
import { RemoveOrganisationModal, ToggleModal, AddUpdateOrganisationModal } from "../components/Modals";
import SubHeader from "../components/parts/SubHeader";
import { Table, SearchBox } from "../components/Table";
import { useGetOrganisationsQuery } from "@/redux/toolkit/accountSlice";

function Organisations() {
  const {data: {Organisations}={}, isLoading, isSuccess:isFetched } = useGetOrganisationsQuery();
  const [organisations, SetOrganisations] = useState(Organisations);
  const [query, setQuery] = useState('');

  useEffect(()=>{
    SetOrganisations(Organisations);
  }, [Organisations]);

  const onSearch = (e) => {
    let searchValue = e.target.value.trimStart();
    setQuery(searchValue);
    if(searchValue) {
      const list = Organisations.filter(organisation => organisation.name.toLowerCase().includes(searchValue.toLowerCase()));
      SetOrganisations(list);
    } else {
      SetOrganisations(Organisations);
    }
  };

  return (
    <>
      <SubHeader>
        <h1>Organizations</h1>
        <p className="text-muted m-0" />
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <ToggleModal
            toggle={(show) => (
              <button
                type='button'
                className='float-right btn btn-outline-primary float-right mb-4'
                onClick={show}
              >
                Add Organization
              </button>
            )}
            content={(hide) => <AddUpdateOrganisationModal hide={hide} />}
          />
          <SearchBox onSearch={onSearch} query={query} />
          <div className="table-responsive my-4">
            <Table
              isLoading={isLoading}
              isFetched={isFetched}
              dataSource={organisations}
              colMapper={[
                {
                  title: "Name",
                  keyName: "name",
                  cellRender: ({ name, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {name}
                      <br />
                      <small className="text-muted">
                        <b>ID</b> {id}
                      </small>
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "Type",
                  keyName: "type",
                  cellRender: ({ type }, key) => (
                    <td className="text-nowrap" key={key}>
                      {type !== "" ? type : null}
                    </td>
                  ),
                },
                {
                  title: "Tier",
                  keyName: "tier",
                  cellRender: ({ tier }, key) => (
                    <td className="text-nowrap" key={key}>
                      {tier !== "" ? tier : null}
                    </td>
                  ),
                },
                {
                  title: "",
                  cellRender: (organisation, key) => (
                    <td className="align-middle text-right" key={key}>
                      <div className="dropdown dropdown-sm">
                        <button
                          className="btn btn-link btn-sm"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="far fa-lg fa-ellipsis-v" />
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <ToggleModal
                            toggle={(show) => (
                              <span className="dropdown-item pointer" onClick={show}>
                                Edit
                              </span>
                            )}
                            content={(hide) => <AddUpdateOrganisationModal hide={hide} initialValues={{ id: organisation.id, name: organisation.name, type: organisation.type, tier: organisation.tier }} />}
                          />
                          <div className="dropdown-divider" />
                          <ToggleModal
                            toggle={(show) => (
                              <span className="dropdown-item pointer text-danger" onClick={show}>
                                Remove
                              </span>
                            )}
                            content={(hide) => (
                              <RemoveOrganisationModal
                                hide={(deleteSuccess = false) => {
                                  hide();
                                }}
                                organisation={organisation}
                                method="removeOrganisation"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </td>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Organisations;
