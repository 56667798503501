import { Form, Formik, Field } from "formik";
import * as jQuery from "jquery";
import { useParams } from "react-router-dom";

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, TextInput } from "../../components/form-fields";
import { AddUpdContactModal, ToggleModal, AddUpdateOrganisationModal } from "../../components/Modals";
import { useGetOrganisationsQuery, useUpdateAccountOrganisationMutation, useRemoveAccountContactMutation, useUpdateAccountMutation, useGetAccountQuery } from "@/redux/toolkit/accountSlice";

const AccountDetailSchema = Yup.object({
  company: Yup.string().required("Company name is a required field."),
  // tier: Yup.string().required("Tier is a required field."),
  // type: Yup.string().required("Type name is a required field."),
});
const OrganisationSchema = Yup.object({
  organisationId: Yup.string().required("Organization name is a required field."),
});
function Details() {

  const [ updateAccountOrganisation ] = useUpdateAccountOrganisationMutation();
  const [removeAccountContact] = useRemoveAccountContactMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const { id: AccID } = useParams();
  let {data: {company,  appAccountId }={}, isLoading: companyLoading } = useGetAccountQuery({
    appAccountId: AccID
  });

  const session = useSelector((state) => state.session);

  const tier = useSelector((state) => state.accounts.getAccount.data?.tier);
  const type = useSelector((state) => state.accounts.getAccount.data?.type);
  const organisationId = useSelector((state) => state.accounts.getAccount.data?.organisation?.id);
  const organisationName = useSelector((state) => state.accounts.getAccount.data?.organisation?.name);
  const role = useSelector((state) => state.session?.role);
  const {data: {Organisations}={}, isLoading } = useGetOrganisationsQuery(undefined, {skip:role !== "super"});

  const onSubmit = (values) => {
    updateAccount({ appAccountId, data: values });
    let segmentData = {
        parentGroupId: organisationId,
        groupType: "account",
        id: session.account && session.account.id ? session.account.id : "",
        name: values.company,
        type: values.type,
        tier: values.tier,
        createdAt: session.account && session.account.createdAt ? session.account.createdAt : "",
    };

    if(session.account && session.account.organisation && session.account.organisation.id) {
      segmentData.parentGroupId = organisationId;
    }

    window.analytics.group(session.account && session.account.id ? session.account.id : "", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : "",
      userId: session.user && session.user.id ? session.user.id : ""
    });
  };

  const onHandleSubmit = async(values) => {
    const organisationDetails = await updateAccountOrganisation({ accountId: appAccountId, data: values }).unwrap();

    window.analytics.group(organisationDetails.payload.account && organisationDetails.payload.account.organisation && organisationDetails.payload.account.organisation.id ? organisationDetails.payload.account.organisation.id : "", {
      groupType: "organization",
      id: organisationDetails.payload.account && organisationDetails.payload.account.organisation && organisationDetails.payload.account.organisation.id ? organisationDetails.payload.account.organisation.id : "",
      name: organisationDetails.payload.account && organisationDetails.payload.account.organisation && organisationDetails.payload.account.organisation.name ? organisationDetails.payload.account.organisation.name : "",
      createdAt: organisationDetails.payload.account && organisationDetails.payload.account.organisation && organisationDetails.payload.account.organisation.createdAt ? organisationDetails.payload.account.organisation.createdAt : "",
      updatedAt: organisationDetails.payload.account && organisationDetails.payload.account.organisation && organisationDetails.payload.account.organisation.updatedAt ? organisationDetails.payload.account.organisation.updatedAt : "",
    });
  };

  const initialValues = {
    company: company ?? "",
    type: type ?? "",
    tier: tier ?? "",
  };

  const Contacts = useSelector((state) => state.accounts.getAccount.data?.contacts);
  const ContactButtonStyles = {
    width: "2.25em",
    height: "2.25em",
    lineHeight: "1.25em",
    background: "transparent",
    marginRight: "5px",
  };

  const isOnlyBillingContact = Boolean(Contacts?.filter((x) => x.isBilling).length === 1);

  useEffect(() => {
    const $ = jQuery;
    $(function () {
      $('[data-bs-toggle="tooltip"]').tooltip();
    });
  }, [ ]);

  useEffect(() => {
    let segmentData = {
      title: `Account Details | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("Account Details", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    })
  }, [session.user, session.account, session.subscription]);


  return (
    <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
      <section className="mb-5">
        <p className="lead">Company</p>
        <div className="card">
          <div className="card-body">
            <Formik initialValues={initialValues} validationSchema={AccountDetailSchema} onSubmit={onSubmit} enableReinitialize>
              {({ dirty, errors, isValidating }) => (
                <Form className="form needs-validation">
                  <div className="form-group mb-3">
                    <div className="form-label-group mb-3">
                      <TextInput label="Company name" name="company" type="text" placeholder="Company name" className="form-control" />
                    </div>
                    {role === "super" ?
                      <>
                        <div className="form-label-group mb-3">
                          <Field as="select" name="type" className="form-control select-tier">
                          <option value={""}>--Select Company Type--</option>
                            <option value={"Customer (Brand)"}>Customer (Brand)</option>
                            <option value={"Customer (Agency/Platform)"}>Customer (Agency/Platform)</option>
                            <option value={"Reseller"}>Reseller</option>
                            <option value={"Supplier"}>Supplier</option>
                            <option value={"Staff"}>Staff</option>
                            <option value={"Investor"}>Investor</option>
                          </Field>
                        </div>
                        <div className="form-label-group mb-3">
                          <Field as="select" name="tier" className="form-control select-tier">
                          <option value={""}>--Select Company Tier--</option>
                            <option value={"Tier 1"}>Tier 1</option>
                            <option value={"Tier 2"}>Tier 2</option>
                            <option value={"Tier 3"}>Tier 3</option>
                            <option value={"Not applicable"}>Not applicable</option>
                          </Field>
                        </div>
                      </>
                      : null}
                  </div>
                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <Button label={"Save"} dirty={dirty} errors={errors} isValidating={isValidating} isSubmitting={companyLoading} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      {role === "admin" &&
      <section className="mb-5">
        <p className="lead">Organization</p>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <div className="form-label-group mb-3">
                <input label="Organization name" name="organisation" type="text" placeholder="Name" value={organisationName? organisationName : ''} disabled className="form-control" />
                <label htmlFor="organisation">Name</label>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      {role === "super" &&
      <section className="mb-5">
        <p className="lead">Organization</p>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-row-reverse">
              <ToggleModal
                toggle={(show) => (
                  <button
                    type='button'
                    className='float-right btn btn-outline-primary float-right mb-4'
                    onClick={show}
                  >
                    Add Organization
                  </button>
                )}
                content={(hide) => <AddUpdateOrganisationModal hide={hide} />}
              />
            </div>
            <Formik initialValues={{organisationId: organisationId ? organisationId : "" }} validationSchema={OrganisationSchema} onSubmit={onHandleSubmit} enableReinitialize>
              {({ dirty, errors, isValidating }) => (
                <Form className="form needs-validation">
                  <div className="form-group mb-3">
                    <div className="form-label-group mb-3">
                      <Field as="select" name="organisationId" className={`form-control select-tier ${errors.organisationId ? "is-invalid": ""}`}>
                      <option value={""}>--Select Organization--</option>
                        {Organisations && Organisations.map( organisation =>
                        <option key={organisation.id} value={organisation.id}>{organisation.name}</option>
                        )}
                      </Field>
                      { errors.organisationId &&
                      <div className="invalid-feedback">{errors.organisationId}</div>
                      }
                    </div>
                  </div>
                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <Button label={"Save"} dirty={dirty} errors={errors} isValidating={isValidating} isSubmitting={isLoading} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      }
      <section className="mb-5">
        <p className="lead">Key contacts</p>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive" style={{ minHeight: "200px" }}>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="text-nowrap" scope="col">
                      <span className="text-uppercase">
                        <small>
                          <b>Contact</b>
                        </small>
                      </span>
                    </th>
                    <th className="text-nowrap" scope="col">
                      <span className="text-uppercase">
                        <small>
                          <b>Phone</b>
                        </small>
                      </span>
                    </th>
                    <th className="text-nowrap" scope="col">
                      <span className="text-uppercase">
                        <small>
                          <b>Notifications</b>
                        </small>
                      </span>
                    </th>
                    <th className="col-actions text-center text-nowrap" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {Contacts?.length ? (
                    Contacts.map((Contact) => {
                      const { id, firstName, lastName, email, phone, isAccount, isBilling } = Contact;

                      return (
                        <tr key={id}>
                          <td className="text-nowrap">
                            {`${firstName} ${lastName}`}
                            <br />
                            <small>{email}</small>
                          </td>
                          <td className="text-nowrap">{phone}</td>
                          <td className="align-top text-nowrap">
                            {Boolean(isAccount) && (
                              <button
                                className="d-inline-block border border-dark rounded-circle text-center text-dark"
                                style={ContactButtonStyles}
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Account notifications"
                              >
                                <i className="far fa-building"></i>
                              </button>
                            )}
                            {Boolean(isBilling) && (
                              <button
                                className="d-inline-block border border-dark rounded-circle text-center text-dark"
                                style={ContactButtonStyles}
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Billing notifications"
                              >
                                <i className="far fa-receipt"></i>
                              </button>
                            )}
                          </td>
                          <td className="align-middle text-right">
                            <div className="dropdown dropdown-sm">
                              <button
                                className="btn btn-link btn-sm"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  <i className="far fa-lg fa-ellipsis-v"></i>
                                </span>
                              </button>
                              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <h6 className="dropdown-header">Actions</h6>
                                <ToggleModal
                                  toggle={(show) => (
                                    <span className="dropdown-item pointer" onClick={show}>
                                      Edit
                                    </span>
                                  )}
                                  content={(hide) => <AddUpdContactModal hide={hide} initialValues={Contact} />}
                                />
                                <div className="dropdown-divider"></div>
                                {Boolean(isBilling && isOnlyBillingContact) ? (
                                  <span className="dropdown-item disabled" aria-disabled="true">
                                    Remove
                                    <br />
                                    <small>(billing contact required)</small>
                                  </span>
                                ) : (
                                  <span
                                    className="dropdown-item pointer"
                                    onClick={(e) => {
                                      removeAccountContact({ appContactId: id, appAccountId });
                                    }}
                                  >
                                    Remove
                                  </span>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>No Records found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <div className="btn-group" role="group" aria-label="First group">
                <ToggleModal
                  toggle={(show) => (
                    <button type="button" className="btn btn-outline-primary" onClick={show}>
                      Add a contact
                    </button>
                  )}
                  content={(hide) => <AddUpdContactModal hide={hide} />}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Details;
