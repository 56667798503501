import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	ChangeUserRoleModal,
	InviteUserModal,
	RemoveUserModal,
	ResetUserMFAModal,
	ToggleModal,
} from '../../components/Modals';
import { useGetAccountUsersQuery } from "@/redux/toolkit/accountSlice";
import { Pagination, SearchBox, Table } from '../Table';
import methods from '../../utilities/methods';

function Users() {
	const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const session = useSelector((state) => state.session);

	const [orderby, setOrderBy] = useState('name');
	const [sortby, setSortby] = useState('desc');
	const [pageIndex, setPageIndex] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [query, setQuery] = useState('');

  const {data: {AccountUsers,  MaxPageIndex,  MaxRecordCount}={}, isFetching:isLoading, isSuccess:isFetched } = useGetAccountUsersQuery({
    appAccountId,
		query,
    orderby,
    sortby,
    pageIndex,
    itemsPerPage,
  });


  useEffect(() => {
    let segmentData = {
      title: `Account Users | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("Account Users", {
			...segmentData
		},
		{
			groupId: session.account && session.account.id ? session.account.id : ""
		})
  }, [session.user, session.account, session.subscription]);


	const SortingFunction = (e, orderby, sortby) => {
		e.preventDefault();
		setOrderBy(orderby);
		setSortby(sortby);
	};

	const onSearch = (e) => {
		setQuery(e.target.value.trimStart());
		setPageIndex(1);
	};

	return (
		<div
			className='tab-pane fade show active'
			id='manageusers'
			role='tabpanel'
			aria-labelledby='manageusers-tab'
		>
			<section className='mb-5'>
				<ToggleModal
					toggle={(show) => (
						<button
							type='button'
							className='float-right btn btn-outline-primary float-right'
							onClick={show}
						>
							Invite a user
						</button>
					)}
					content={(hide) => (
						<InviteUserModal
							hide={(inviteSuccess = false) => {
								hide();
							}}
						/>
					)}
				/>
				<p className='lead'>Users</p>
				<SearchBox onSearch={onSearch} query={query} />
				<div className='table-responsive my-4'>
					<Table
						orderby={orderby}
						sortby={sortby}
						isLoading={isLoading}
						isFetched={isFetched}
						dataSource={AccountUsers}
						colMapper={[
							{
								title: 'Name',
								keyName: 'name',
								onSorting: SortingFunction,
								cellRender: (item, key) => (
									<td className='text-nowrap' key={key}>
										{`${item.firstName} ${item.lastName}`}
										{item.role === 'admin' && (
											<small className='d-inline-block border border-dark bg-dark text-white rounded-sm px-1 ml-1'>
												Admin
											</small>
										)}
										<br />
										<span className='small text-muted'>{item.email}</span>
									</td>
								),
							},
							{
								title: 'Status',
								keyName: 'state',
								onSorting: SortingFunction,
								cellRender: (item, key) => (
									<td className='text-nowrap' key={key}>
										{item.state ==='active' ? methods.ucFirst(item.state): "MFA reset" }
										<br />
										<span className='small text-muted'>
											{item.lastLoginAt
												? `Last seen ${new Date(
														Date.parse(item.lastLoginAt)
												  ).toDateString()}`
												: `Never signed in`}
										</span>
									</td>
								),
							},
							{
								title: 'Subscriptions',
								keyName: 'subscriptions',
								cellRender: (item, key) => (
									<td className='text-nowrap' key={key}>
										<small>
											{item.subscriptions.map((subscription, i) => {
												let returnVal = (
													<>
														<i className='far fa-check mr-1'></i>{' '}
														{subscription.plan.name}
													</>
												);
												if (i === 0) {
													return (
														<React.Fragment key={`subscription-${i}`}>
															{returnVal}
														</React.Fragment>
													);
												} else {
													return (
														<React.Fragment key={`subscription-${i}`}>
															<br />
															{returnVal}
														</React.Fragment>
													);
												}
											})}
										</small>
									</td>
								),
							},
							{
								title: '',
								cellRender: (item, key) => (
									<td className='align-middle text-right' key={key}>
										<div className='dropdown dropdown-sm'>
											<button
												className='btn btn-link btn-sm'
												type='button'
												id='dropdownMenuButton'
												data-bs-toggle='dropdown'
												aria-haspopup='true'
												aria-expanded='false'
											>
												<span>
													<i className='far fa-lg fa-ellipsis-v'></i>
												</span>
											</button>
											<div
												className='dropdown-menu dropdown-menu-right'
												aria-labelledby='dropdownMenuButton'
											>
												<h6 className='dropdown-header'>Actions</h6>
												<ToggleModal
													toggle={(show) => (
														<span
															className='dropdown-item pointer'
															onClick={show}
														>
															Change role
														</span>
													)}
													content={(hide) => (
														<ChangeUserRoleModal
															hide={(deleteSuccess = false) => {
																hide();
															}}
															user={item}
														/>
													)}
												/>
												{(item.state === "active" || !item.state) &&
												<ToggleModal
													toggle={(show) => (
														<span
															className='dropdown-item pointer'
															onClick={show}
														>
															Reset MFA
														</span>
													)}
													content={(hide) => (
														<ResetUserMFAModal
															hide={(deleteSuccess = false) => {
																hide();
															}}
															user={item}
														/>
													)}
												/> }
												{/* <button className="dropdown-item">Change password</button> */}
												<div className='dropdown-divider'></div>
												<ToggleModal
													toggle={(show) => (
														<span
															className='dropdown-item pointer'
															onClick={show}
														>
															Remove
														</span>
													)}
													content={(hide) => (
														<RemoveUserModal
															hide={(deleteSuccess = false) => {
																hide();
															}}
															user={item}
															method='removeAccountUser'
														/>
													)}
												/>
											</div>
										</div>
									</td>
								),
							},
						]}
					/>
				</div>
				<nav className='navbar navbar-expand-lg navbar-light border-top'>
					{AccountUsers?.length > 0 && (
						<Pagination
							key={`${itemsPerPage}-${orderby}-${sortby}`}
							maxPageIndex={MaxPageIndex}
							pageIndex={pageIndex}
							totalRecords={MaxRecordCount}
							pageLimit={itemsPerPage}
							pageNeighbours={2}
							onPageChanged={({ currentPage }) => setPageIndex(currentPage)}
							onLimitChange={(itemsPerPage) => {
								setPageIndex(1);
								setItemsPerPage(itemsPerPage);
							}}
						/>
					)}
				</nav>
			</section>
		</div>
	);
}

export default Users;
