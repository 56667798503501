import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { DeleteAccountModal, CloneContextPack, CloneBenchmark, ToggleModal } from "../components/Modals";
import SubHeader from "../components/parts/SubHeader";
import Pluralize from "../components/Pluralize";
import { Pagination, SearchBox, Table } from "../components/Table";
import { useGetAllAccountsQuery } from "@/redux/toolkit/accountSlice";
import methods from "../utilities/methods";

function AccountList() {
  const appAccountId = useSelector(
    (state) => state.accounts.getAccount.data?.id
  );

  const [orderby, setOrderBy] = useState("name");
  const [sortby, setSortby] = useState("asc");
  const [pageIndex, setPageIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const {data: {Accounts,  MaxPageIndex,  MaxRecordCount}={}, isLoading, isSuccess:isFetched } = useGetAllAccountsQuery({
    appAccountId,
    query,
    orderby,
    sortby,
    pageIndex,
    itemsPerPage,
  });

  // Passed through the colMapper to allow role specific renders - currently only implimented
  // in specific use cases check Table.js component for details
  const role = useSelector((state) => state.session.role);

  const SortingFunction = (e, orderby, sortby) => {
    e.preventDefault();
    setOrderBy(orderby);
    setSortby(sortby);
  };

  const onSearch = (e) => {
    setQuery(e.target.value.trimStart());
    setPageIndex(1);
  };

  const StatusMapping = {
    active: {
      label: "Active",
      class: "success",
    },
    nonrenewing: {
      label: "Non Renewing",
      class: "success",
    },
    intrial: {
      label: "In Trial",
      class: "warning",
    },
    future: {
      label: "Future",
      class: "info",
    },
    paused: {
      label: "Paused",
      class: "danger",
    },
    cancelled: {
      label: "Cancelled",
      class: "dark",
    },
  };

  return (
    <>
      <SubHeader>
        <h1>Accounts</h1>
        <p className="text-muted m-0">
          An account is a fully segregated instance of Dragonfly AI with a
          unique billing profile.
        </p>
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <SearchBox onSearch={onSearch} query={query} />

          <div className="table-responsive my-4">
            <Table
              orderby={orderby}
              sortby={sortby}
              isLoading={isLoading}
              isFetched={isFetched}
              dataSource={Accounts}
              colMapper={[
                {
                  title: "Name",
                  keyName: "name",
                  onSorting: SortingFunction,
                  cellRender: ({ id, company }, key) => (
                    <td className="text-nowrap" key={key}>
                      <NavLink to={`/accounts/${id}/details`}>
                        {company}
                      </NavLink>
                      <br />
                      <small className="text-muted">
                        <b>ID</b> {id}
                      </small>
                    </td>
                  ),
                  width: "300px",
                },
                {
                  title: "Users",
                  keyName: "usercount",
                  onSorting: SortingFunction,
                  cellRender: ({ userCount }, key) => (
                    <td className="text-nowrap" key={key}>
                      {userCount}
                    </td>
                  ),
                },
                {
                  title: "Subscriptions",
                  keyName: "subscriptions",
                  cellRender: ({ subscriptions }, key) => (
                    <td className="text-nowrap" key={key}>
                      <span>
                        <Pluralize
                          singular={"subscription"}
                          count={subscriptions.length}
                        />
                      </span>
                      <br />
                      <small>
                        {subscriptions.map(({ status }, index) => (
                          <span
                            key={index}
                            className={`d-inline-block border mr-1 border-${StatusMapping[status]?.class} text-${StatusMapping[status]?.class} px-1`}
                          >
                            1{" "}
                            {StatusMapping[status]
                              ? StatusMapping[status].label
                              : methods.ucFirstLetter(status)}
                          </span>
                        ))}
                      </small>
                    </td>
                  ),
                },
                {
                  title: "Tier",
                  keyName: "tier",
                  role: role,
                  cellRender: ({ tier }, key) => (
                    <td className="text-nowrap" key={key}>
                      {tier !== "" ? tier : null}
                    </td>
                  ),
                },
                {
                  title: "Type",
                  keyName: "type",
                  role: role,
                  cellRender: ({ type }, key) => (
                    <td className="text-nowrap" key={key}>
                      {type !== "" ? type : null}
                    </td>
                  ),
                },
                {
                  title: "Created",
                  keyName: "createdat",
                  onSorting: SortingFunction,
                  cellRender: ({ createdAt }, key) => (
                    <td className="text-nowrap" key={key}>
                      {methods.formatDate(createdAt)}
                    </td>
                  ),
                },
                {
                  title: "",
                  cellRender: ({ id, company }, key) => (
                    <td className="align-top text-right" key={key}>
                      <div className="dropdown dropdown-sm">
                        <button
                          className="btn btn-link btn-sm"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="far fa-lg fa-ellipsis-v"></i>
                          </span>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <h6 className="dropdown-header">Actions</h6>
                          <NavLink
                            className="dropdown-item"
                            to={`/accounts/${id}/details`}
                          >
                            View
                          </NavLink>
                          <ToggleModal
                            toggle={(show) => (
                              <span
                                className="dropdown-item pointer"
                                onClick={show}
                              >
                                Apply Context Pack
                              </span>
                            )}
                            content={(hide) => (
                              <CloneContextPack
                                hide={(success = false) => {
                                  hide();
                                }}
                                data={{ accountId: id }}
                              />
                            )}
                          />
                          <ToggleModal
                            toggle={(show) => (
                              <span
                                className="dropdown-item pointer"
                                onClick={show}
                              >
                                Apply Benchmark
                              </span>
                            )}
                            content={(hide) => (
                              <CloneBenchmark
                                hide={(success = false) => {
                                  hide();
                                }}
                                data={{ accountId: id }}
                              />
                            )}
                          />
                          <div className="dropdown-divider"></div>
                          <ToggleModal
                            toggle={(show) => (
                              <span
                                className="dropdown-item pointer text-danger"
                                onClick={show}
                              >
                                Delete
                              </span>
                            )}
                            content={(hide) => (
                              <DeleteAccountModal
                                hide={(deleteSuccess = false) => {
                                  hide();
                                }}
                                data={{ id, company }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </td>
                  ),
                },
              ]}
            />
          </div>
          <nav className="navbar navbar-expand-lg navbar-light border-top">
            {Accounts?.length > 0 && (
              <Pagination
                key={`${itemsPerPage}-${orderby}-${sortby}`}
                maxPageIndex={MaxPageIndex}
                pageIndex={pageIndex}
                totalRecords={MaxRecordCount}
                pageLimit={itemsPerPage}
                pageNeighbours={2}
                onPageChanged={({ currentPage }) => setPageIndex(currentPage)}
                onLimitChange={(itemsPerPage) => {
                  setPageIndex(1);
                  setItemsPerPage(itemsPerPage);
                }}
              />
            )}
          </nav>
        </div>
      </section>
    </>
  );
}

export default AccountList;